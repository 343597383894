<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
    <!-- Table Container Card -->
    <b-card no-body>
    
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2">
            <!-- <label>Status</label> -->
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sites"
              label="site_name"
              class="w-100"
              placeholder="Project Site"
              @input="filterTable"
              v-model="siteData"
              :clearable="false"
            >
              <template #selected-option="{ site_name }">
                <span v-if="site_name.length < 23">{{site_name}}</span>
                <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
              </template>
            </v-select>
          </b-col>

          

          <b-col 
           cols="12"
           md="9"
           class="mb-md-0 mb-2"
          >

            <div class="d-flex align-items-center justify-content-end">
            </div>
          </b-col>
          
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end mobile-view">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input"
                placeholder="Search..."
                @input="filterTable"
              />
              <b-button
                
                variant="warning"
                :to="{name:'add-safety-inspection-questions'}"
                class="mr-1 mobile-margin-bottom"
              >
                <span class="text-nowrap">Add</span>
              </b-button>

              <b-button 
                  
                  variant="primary"
                  class="mr-1 mobile-margin-bottom"
                  @click="openAssignSiteModel"
              >
                  <span class="text-nowrap">Assign To Project Site</span>
              </b-button>

              <b-button
                  
                  variant="danger" 
                  class="mr-1 mobile-margin-bottom" 
                  @click="changeMultipleStatus('archive')"
              >
                  <span class="text-nowrap">Delete</span>
              </b-button>

            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative sTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <template #head(checkbox)="items">
            <b-form-checkbox class="custom-control-warning" v-model="selectAll" @change="selectall"/>
        </template>

        <template #cell(checkbox)="items">
            <b-form-checkbox v-model="selected" @change="selectAll = false" :value="items.item._id" class="custom-control-warning my-1"/>
        </template>

        <!-- Column: User -->
        <template #cell(site)="items">
           
            <span class="wordBreak">{{ items.item.site.site_name | capitalize}}</span>
         
        </template>

        <!-- Column: Role -->
        <template #cell(en_question)="items">
           
            <span class="wordBreak">{{ items.item.en_question | capitalize}}</span>
         
        </template>

        
        <template #cell(status)="items">
           
            <b-badge
              pill
              :variant="variantColor(items.item.status)"
              class="text-capitalize"
            >
              {{ items.item.status}}
            </b-badge>
         
        </template>

        <template #cell(created_at)="items">
           
            <span>{{ items.item.created_at | dateTime}}</span>
         
        </template>

        <template #cell(actions)="items">

          
          <!-- <b-dropdown
          
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            

            <b-dropdown-item :to="{ name: 'edit-safety-inspection-questions', params: { id: items.item._id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="statusChange(items.item)">
              <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
              <span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
              <span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
            </b-dropdown-item>

            <b-dropdown-item  @click="deleteItem(items.item._id)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            

            
          </b-dropdown>  -->

             <b-link :to="{ name: 'edit-safety-inspection-questions', params: { id: items.item._id } }"
            v-b-tooltip.hover.v-warning
            title="Edit"
            variant="outline-warning"
          >
              <feather-icon icon="EditIcon" class="mediumSize mr-1"/>
              
          </b-link>

            <b-link v-b-tooltip.hover.v-warning :title= "items.item.status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" @click="statusChange(items.item)">
                <feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />	
            </b-link>

           <b-link  @click="deleteItem(items.item._id)"
            v-b-tooltip.hover.v-warning
            title="Delete"
            variant="outline-warning"
          >
              <feather-icon icon="Trash2Icon" class="mediumSize mr-1"/>
              
          </b-link>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal 
        id="pass"
        ref="pass"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Verify Password"
        @ok="verifyPassword"
        no-close-on-backdrop
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
            <div class="alert-body"> {{error_message}} </div>
        </b-alert>

        <b-form>
            <b-row>
                <b-col md="12">
                    <b-form-group label="Password" class="required">
                        <b-form-input placeholder="" v-model="password"/>
                    </b-form-group>
                </b-col>
            </b-row>      
        </b-form>

    </b-modal>

    <!-- Assign To Project Site Model -->
    <b-modal
        id="assign_site"
        ref="assign_site"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Assign To Project Site"
        @ok="updateSite"
        no-close-on-backdrop
    >

        <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
            <div class="alert-body">
                {{error_message}}
            </div>
        </b-alert>

        <b-form>
            <b-row>
                <b-col cols="12" md="12" class="mb-md-0 mb-2">
                <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="addSites"
                    label="site_name"
                    class="w-100"
                    placeholder="Select"
                    v-model="site_id"
                    :clearable="false"
                />
                </b-col>
            </b-row>
        </b-form>
    </b-modal>

  </div>
</template>

<script>
import {
  BCard,VBTooltip, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BCardBody,
  BFormCheckbox, BAlert, BFormText, BFormGroup, BForm, BBreadcrumb
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BFormCheckbox, 
    BAlert, 
    BFormText, 
    BFormGroup, 
    BForm,
    BBreadcrumb
  },
  directives: {
      'b-tooltip': VBTooltip
  },
  data() {
    return {
      error_message: null, 
      showDismissibleAlert:false,

      tableColumns: [
            { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '1%'}},
            { key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '20%'}},
            { key: 'en_question', label: 'Question', sortable: true , thStyle:  {width: '25%'}},
            { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '10%'}},
            { key: 'created_at', label: 'Added On', sortable: true , thStyle:  {width: '13%'}},
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '16%'}},
          ],
        
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        siteData:null,
        sites:[],

        // multicheckbox vars
        selectAll : false,
        selected  : [],
        delType   : '',
        delStatus : '',
        password  : '',

        // Assign To Project Site vars
        addSites : [],
        site_id  : '',
        popshowDismissibleAlert:false,
    }
  },
  methods : {
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                     site:this.siteData ? this.siteData._id : null,
                   },
                   api: '/api/inspection-questions-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    statusChange(item){
      if (item.status == 'active') {
          var msg = 'Are you sure want to inactivate this record?';
          var message='Record Inactivated Successfully.';
      } 
      else{
          var msg = 'Are you sure want to activate this record?';
          var message='Record Activated Successfully.';
      }

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: item._id,
                          status:item.status
                        },
                    api:"/api/change-inspection-question-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
             
          })
    },

    deleteItem(id){
      var msg = 'Are you sure want to delete this record?';
      var message='Record Deleted Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: id,
                          status:'deleted'
                        },
                    api:"/api/change-inspection-question-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
              
          })
    },
    allSites(){
      return this.$store.dispatch(POST_API, {
         data:{
              role:this.$store.getters.currentUser.role,
              //om_sites:this.$store.getters.currentUser.om_sites,
         },
         api: '/api/all-sites'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.sites    = this.$store.getters.getResults.data;
              this.addSites = JSON.parse(JSON.stringify(this.$store.getters.getResults.data));
              /*this.siteData = this.sites.length > 0 ? this.sites[0] : null;
              this.filterTable();*/

              if (this.sites.length == 1 ) {
                  this.siteData = this.sites[0];

                  this.filterTable();
              }

              var obj = {
                _id:'all-site',
                site_name:'All Project Sites'
              }
              this.sites.unshift(obj);
              return this.sites;
          }
      });
    },

    // multicheckbox functions
    selectall(e){
        this.selected = [];
        if (e) {
            var selected = [];

            this.items.forEach(function(item){
                selected.push(item._id);
            })

            this.selected = selected;
        }
    },

    changeMultipleStatus(status){
        this.password = '';

        this.delType = 'multiple';
        this.delStatus = status;

        if (this.selected.length > 0) {
            var msg = '';
            var msg2 = '';

            if (status == 'archive') {
                msg = 'Are you sure you want to delete selected record?';
                msg2 = 'Records Deleted Successfully.';
            }if (status == 'active') {
                msg = 'Are you sure you want to Activate selected record?';
                msg2 = 'Records Activated Successfully.';
            }if (status == 'inactive') {
                msg = 'Are you sure you want to inactive selected record?';
                msg2 = 'Records Inactivated Successfully.';
            }

            Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            })
            .then(result => {
                if (result.value) {
                    this.$refs['pass'].show();
                }
            })
            .catch(err => {
                // An error occurred
            })   
        }else{
            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Please select Question(s).',
                showConfirmButton: false,
                timer: 1500
            })
        }
    },

    verifyPassword(e){
        e.preventDefault();

        return this.$store.dispatch(POST_API, {
            data:{
                user_id: this.$store.getters.currentUser._id,
                password:this.password
            },
            api:"/api/verify-password",
            })
            .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                this.deleteMultiQuestion();
                this.$refs['pass'].hide();
            }
        });
    },

    deleteMultiQuestion(){
        var message='Project Site Deleted Successfully.';
        
        return this.$store.dispatch(POST_API, {
            data:{
                id     : this.selected,
                status :'deleted'
                },
            api:"/api/change-safety-inspection-questions-status-multi",
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showAlert();
            } else {
                this.successAlert();
                this.$refs.refUserListTable.refresh();
                this.selectAll = false;
            }
        });
    },

    // Assign To Project Site functions
    openAssignSiteModel(){
        this.popshowDismissibleAlert = false;
        this.site = null;
        this.$refs['assign_site'].show();
    },

    updateSite(bvModalEvt){
        bvModalEvt.preventDefault()
        if (this.selected.length > 0) {
            return this.$store.dispatch(POST_API, {
                data:{
                    site : this.site_id ? this.site_id._id : null,
                    question_id : this.selected.reverse(),
                },
                api : '/api/add-site-safety-inspection-questions'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.popshowDismissibleAlert = true;
                } else {
                    this.popshowDismissibleAlert = false;
                    var message  = this.$store.getters.getResults.message;
                    this.successAlert()
                    .then((result) => {
                        this.filterTable();
                        this.$refs['assign_site'].hide();
                        this.selected = [];
                        this.site_id  = '';
                        this.selectAll = false;
                    });
                }
            });
        }else{
            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Please select Question(s).',
                showConfirmButton: false,
                timer: 1500
            })
        }
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:{name:'custom-forms'},
        text: 'Custom Forms',
      },{
        to:{name:'create-safety-inspection'},
        text:'Quaterly Safety Inspection'
      },{
        to:null,
        text:'Questions'
      }];
      return item;
    }
  },
  mounted(){
    this.allSites();
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
